<template>
  <zg-section id="U5ZPWwni7agLO22J7sqLF">
    <div class="trust-quote-section">
      <h2 v-if="title">
        {{ title }}
      </h2>

      <div class="trust-quote-section--person">
        <zg-avatar
          text="LK"
        />

        <div class="name">
          <span class="first-name">{{ name }}</span>
          <span>{{ profession }}</span>
        </div>
      </div>

      <div>
        <div class="trust-quote-section--arrow" />
        <div class="trust-quote-section--text">
          <p>{{ quote }}</p>
        </div>
      </div>

      <div class="trust-quote-section--trust">
        <zg-trustpilot
          :stars="stars"
          :inline-title="trustTitle"
          :inline-text="trustText"
        />

        <p>{{ disclaimer }}</p>
      </div>
    </div>
  </zg-section>
</template>

<script setup lang="ts">
  import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
  import { computed, defineAsyncComponent, type PropType } from 'vue'

  const ctx = import.meta.env.SSR
    ? useSSRContext()
    : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale')
    })

  const ZgAvatar = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgAvatar))
  const ZgSection = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgSection))
  const ZgTrustpilot = defineAsyncComponent(async () => await import('@ocp-zmarta/zmarta-components').then(c => c.ZgTrustpilot))

  const props = defineProps({
    title: {
      type: String as PropType<string>,
      default: null
    },
    trustpilotData: {
      type: Object as PropType<{ stars: number, scoreTemplate: string }>,
      default: () => ({})
    },
    trustTitle: {
      type: String as PropType<string>,
      default: null
    },
    imageSrc: {
      type: String as PropType<string>,
      default: null
    },
    name: {
      type: String as PropType<string>,
      default: null
    },
    profession: {
      type: String as PropType<string>,
      default: null
    },
    quote: {
      type: String as PropType<string>,
      default: null
    }
  })

  const stars = computed(() => {
    return props.trustpilotData?.stars
  })

  const trustText = computed(() => {
    return props.trustpilotData?.scoreTemplate?.replace(/<\/?[^>]+(>|$)/g, '')
  })

  const disclaimer = computed(() => {
    const content = {
      sv: 'Alla som har tagit lån, valt elavtal eller försäkring via Zmarta får frågan via mejl att tycka till om oss på Trustpilot. Omdömen av verifierade kunder är markerade – de som syns på vår sajt är utvalda. Vårt Trustpilot-betyg är baserat på alla omdömen av både verifierade kunder och icke-verifierade användare, och beräknas med hjälp av faktorer som antal, stjärnbetyg och annat.',
      en: 'We do not verify that reviews submitted about us come from people who have used our services.',
      nb: 'Vi kontrollerer ikke at anmeldelser som er gitt om oss kommer fra personer som har brukt tjenestene våre.',
      fi: 'Emme varmista jälkikäteen, että meistä tehdyt arvostelut ovat ihmisiltä, jotka ovat käyttäneet palveluitamme.'
    }

    return content[ctx?.locale as keyof typeof content]
  })
</script>

<style lang="scss" scoped>
  .trust-quote-section {
    display: flex;
    flex-direction: column;
    place-content: center center;
    max-width: 68.125rem;
    margin: 0 auto;

    &--person {
      display: flex;

      .name {
        @include type-body-s;

        display: flex;
        flex-direction: column;
        padding-left: rem(12px);

        @include mq(large) {
          flex-direction: row;
          padding-left: rem(64px);
        }
      }

      .first-name {
        @include type-title-s;

        margin: 0;

        @include mq(large) {
          padding-right: rem(8px);
        }
      }

      :deep(.zg-avatar--size-xlarge) {
        background-color: var(--white);
        width: rem(56px);
        height: rem(56px);
        border-radius: 50%;

        @include mq(large) {
          width: rem(96px);
          height: rem(96px);
        }
      }
    }

    &--text {
      background: var(--secondary-500);
      padding: rhythm();
      margin: 0 0 rhythm(small);
      border-radius: radius(large);

      p {
        @include type-title-m;

        margin: 0;
      }

      position: relative;
      z-index: 1;

      @include mq(large) {
        margin-left: rem(128px);
        margin-top: rem(-96px);
        border-radius: rem(48px);
        padding: rem(32px);

        p {
          @include type-title-l;

          margin: 0;
        }
      }
    }

    &--trust {
      display: flex;
      flex-direction: column;

      @include mq(medium) {
        justify-content: center;
      }

      @include mq(large) {
        justify-content: flex-start;
        margin-left: rem(160px);
      }

      p {
        @include type-body-m;

        margin: rem(12px) 0 0;
      }
    }

    &--arrow {
      width: rem(48px);
      height: rem(60px);
      transform: matrix(1.1, 1, -0.25, -1, 58, 36);
      position: relative;
      z-index: 0;
      background: var(--secondary-500);
      border-bottom-left-radius: rem(20px);
      margin-top: - rhythm();

      @include mq(large) {
        border-top-left-radius: rem(12px);
        transform: matrix(0.88, 0.19, 0.79, 1.17, 110, 20);
      }
    }
  }
</style>
